import React from "react";

import { useTranslation } from "react-i18next";

import girl from '../../img/ForInvestors/girl.png'

function Ready () {

      const { t } = useTranslation();

    return(
        <div className="max-w-[874px] mx-[20px] xl:mx-auto mt-[45px] xl:mt-[90px] bg-[#7A8FFF] xl:h-[314px] rounded-[15px] p-[20px] xl:p-[40px] relative">
            <p className="text-white font-[800] text-[15px] xl:text-[30px] xl:max-w-[387px] leading-[1.5]">{t('forinvestors.9')}</p>
            <p className="mt-[10px] text-white font-[300] leading-[1.5] xl:max-w-[379px]">{t('forinvestors.10')}</p> 
            <div className="buttoninvestors mt-[20px] xl:mt-[30px]">
                <p className="inter text-[14px] xl:text-[16px] text-[#222B40] font-[800]">{t('forinvestors.11')}</p>
            </div>
            <img src={girl} alt="" className="xl:absolute bottom-0 right-[40px] mt-[20px] xl:mt-0 mb-[-20px] xl:mb-0 mx-auto"/>
        </div>
    );
}

export default Ready;