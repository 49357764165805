import React from "react";
import $ from 'jquery';
import { useEffect, useState } from "react";
import './App.scss';

import { images } from "./imagesToLoad";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import TradingInstruments from "./pages/TradingInstruments";
import FeesCommissions from "./pages/FeesCommissions";
import AccountTypes from "./pages/AccountTypes";
import SupportFAQ from "./pages/SupportFAQ";
import AboutUs from "./pages/AboutUs";
import EducationPage from "./pages/Education";
import ForInvestors from "./pages/ForInvestors";

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
  <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tradinginstruments" element={<TradingInstruments />} />
          <Route path="/fees" element={<FeesCommissions />} />
          <Route path="/account" element={<AccountTypes/>} />
          <Route path="/support" element={<SupportFAQ/>} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/edu" element={<EducationPage/>} />
          <Route path="/forinvestors" element={<ForInvestors/>} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
