import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import main from '../../img/ForInvestors/main.png'

function Main () {

      const { t } = useTranslation();

      const [isHovered, setIsHovered] = useState(false);

      const handleMouseEnter = () => {
          setIsHovered(true);
      };
      
      const handleMouseLeave = () => {
          setIsHovered(false);
      };
    
    return(
        <div className="max-w-[1280px] mx-[20px] xl:mx-auto bg-investors xl:h-[463px] xl:mt-[100px] p-[20px] xl:p-[70px] relative">
            <p className="text-[#637BFF] text-[16px] xl:text-[30px] open-sans font-[800] xl:max-w-[264px] leading-[1.5]">{t('forinvestors.1')}</p>
            <p className="mt-[20px] xl:mt-[30px] text-white font-[600] open-sans text-[14px] xl:text-[16px]">{t('forinvestors.2')}</p>
            <p className="mt-[15px] xl:max-w-[476px] open-sans text-white font-[300] leading-[1.5]">{t('forinvestors.3')}</p>
            <a href="https://user.snb-capital.limited/login">
                <div 
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="flex w-[200px] buttonmain cursor-pointer mt-[40px] items-center"
                >
                    <div style={{ width: isHovered ? '100%' : '38.774px' }} className={`z-50 transition-all duration-500 h-[38.774px] rounded-full bg-[#181719] flex items-center justify-center`}>
                        <svg className="transition-all duration-500" style={{ rotate: isHovered ? '-135deg' : '0deg' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <g clip-path="url(#clip0_1175_524)">
                                <path d="M8.04175 11.7496L14.9945 4.79688L16.1367 5.93911L9.18398 12.8919H15.312V14.5075H6.42615V5.62164H8.04175V11.7488V11.7496Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1175_524">
                                    <rect width="19.3872" height="19.3872" fill="white" transform="translate(0.771606 0.771484)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p className={`text-[#080808] roboto text-[16px] ml-[45px] absolute`}>{t('main.4')}</p>
                </div>
            </a>
            <img src={main} alt="" className="xl:absolute right-[80px] bottom-0 mb-[-20px] xl:mb-0 mt-[20px] mx-auto xl:mt-0"/>
        </div>
    );
}

export default Main;