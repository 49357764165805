import React, { useEffect, useState, useContext } from "react";
import classes from './Form.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Input from "./Input/Input";
import Phone from './Input/Phone';
import { validateString, validateEmail, validatePhone, validateAgree } from "./validation";
import axios from 'axios';
import successIcon from '../../../assets/img/InvestorsPage/Form/success.svg';
import errorIcon from '../../../assets/img/InvestorsPage/Form/error.svg';
import processIcon from '../../../assets/img/InvestorsPage/Form/process.svg';

const apiUrl = 'https://civbt.xyz/api/bot/JgjimwjQNI';

const Form = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState(false);
    const [process, setProcess] = useState(false);
    const [error, setError] = useState(false);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [termsAgree, setTermsAgree] = useState(false);

    const [nameValid, setNameValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [commentValid, setCommentValid] = useState(true);
    const [termsAgreeValid, setTermsAgreeValid] = useState(true);

    const setDataDefaultState = () => {
        setName('');
        setPhone('');
        setEmail('');
        setComment('');
        setTermsAgree(false);
        setNameValid(true);
        setPhoneValid(true);
        setEmailValid(true);
        setCommentValid(true);
        setTermsAgreeValid(true);
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];

        validArr.push(validateString(name, setNameValid));
        validArr.push(validatePhone(phone, setPhoneValid));
        validArr.push(validateEmail(email, setEmailValid));
        validArr.push(validateString(comment, setCommentValid));
        validArr.push(validateAgree(termsAgree, setTermsAgreeValid));

        validArr.forEach((item) => {
            if (!item) {
                valid = false;
            }
        });

        return valid;
    };

    const sendMessage = async () => {

        setProcess(true);

        const messageData = {
            name,
            phone,
            email,
            comment,
        };

        setDataDefaultState();

        try {
            const data = await axios.post(apiUrl, messageData);

            setProcess(false);

            if (data.status === 200) {
                setSuccess(true);
            } else {
                setError(true);
            }

        } catch (error) {
            setProcess(false);
            setError(true);
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        !nameValid && validateString(name, setNameValid);
        // eslint-disable-next-line
    }, [name]);
    useEffect(() => {
        !phoneValid && validatePhone(phone, setPhoneValid);
        // eslint-disable-next-line
    }, [phone]);
    useEffect(() => {
        !emailValid && validateEmail(email, setEmailValid);
        // eslint-disable-next-line
    }, [email]);
    useEffect(() => {
        !commentValid && validateString(comment, setCommentValid);
        // eslint-disable-next-line
    }, [comment]);
    useEffect(() => {
        !termsAgreeValid && validateAgree(termsAgree, setTermsAgreeValid);
        // eslint-disable-next-line
    }, [termsAgree]);

    return (
        <section className={classes.wrap} id="feedback__form">
            <div className="container">
                <div className={classes.body}>
                    <div className={classes.content}>
                        <h2 className={`${classes.title} font-48`}>
                            {t('investors.form.title')}
                        </h2>
                        <p className={`${classes.text} font-20`}>
                            {t('investors.form.text')}
                        </p>
                    </div>
                    <form className={classes.formWrap}>
                        <div className={`${classes.status} ${classes.statusError} ${error ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={errorIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                <Trans>
                                    {t('investors.form.error')}
                                </Trans>
                            </p>
                        </div>
                        <div className={`${classes.status} ${classes.statusSuccess} ${success ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={successIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                <Trans>
                                    {t('investors.form.success')}
                                </Trans>
                            </p>
                        </div>
                        <div className={`${classes.status} ${classes.statusProcess} ${process ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={processIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                {t('investors.form.process')}
                            </p>
                        </div>
                        <div className={`
                        ${classes.form}
                        ${(process || success || error) ? classes.blur : ''}
                    `}>
                            <div className={classes.inputs}>
                                <Input
                                    value={name}
                                    setValue={setName}
                                    valid={nameValid}
                                    type="text"
                                    label={t('investors.form.name')}
                                    placeholder={t('investors.form.name.plc')}
                                />
                                <Phone
                                    value={phone}
                                    setValue={setPhone}
                                    valid={phoneValid}
                                    label={t('investors.form.phone')}
                                    placeholder={t('investors.form.phone.plc')}
                                />
                                <Input
                                    value={email}
                                    setValue={setEmail}
                                    valid={emailValid}
                                    type="email"
                                    label={t('investors.form.email')}
                                    placeholder={t('investors.form.email.plc')}
                                />
                                <Input
                                    value={comment}
                                    setValue={setComment}
                                    valid={commentValid}
                                    type="text"
                                    label={t('investors.form.comment')}
                                    placeholder={t('investors.form.comment.plc')}
                                />
                            </div>
                            <div className={`${classes.check} ${!termsAgreeValid && classes.incorrect}`}>
                                <div
                                    className={`${classes.checkBox} ${termsAgree && classes.checkBoxActive}`}
                                    onClick={() => setTermsAgree(value => !value)}
                                >
                                </div>
                                <p className={classes.checkText}>
                                    {/* eslint-disable-next-line */}
                                    <Trans components={{ a: <a href="/docs/Terms.pdf" target="_blank"></a> }}>
                                        {t('investors.form.terms_agree')}
                                    </Trans>
                                </p>
                            </div>
                            <div className={classes.submit}>
                                <div onClick={submit} className="buttonheader !py-[10px] cursor-pointer">
                                    <p className="text-[16px] xl:text-[20px] open-sans font-[600] text-white">{t('investors.form.submit')}</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Form;
